<template>
  {{ formatted }}
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { DateType } from "@/types";
export default defineComponent({
  props: {
    date: {
      type: Object as PropType<DateType>,
      required: true,
    },
  },
  computed: {
    formatted(): string {
      let date = this.date;
      if (typeof date == "number") return date.toString();
      if (typeof date == "string") {
        try {
          date = new Date(date);
        } catch (e) {
          return date;
        }
      }
      return date.toLocaleDateString();
    },
  },
});
</script>
