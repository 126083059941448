<template>
  <v-container>
    <v-row justify="space-around">
      <TooltipIcon
        label="source"
        v-if="project.source"
        :destination="project.source"
        :icon="icon"
        location="top"
      />
      <TooltipIcon
        label="launch_app"
        v-if="project.url"
        :destination="project.url"
        :icon="mdiLaunch"
        location="top"
      />
      <!--      <TooltipIcon-->
      <!--        label="info"-->
      <!--        v-if="pageContext.urlParsed.pathname !== infoUrl"-->
      <!--        :destination="infoUrl"-->
      <!--        :icon="mdiInformation"-->
      <!--        location="top"-->
      <!--      />-->
    </v-row>
    <v-row v-if="project.tags">
      <TagGroup :tags="project.tags" />
    </v-row>
  </v-container>
</template>
<script lang="ts">
import translate from "@/services/translate";
import { defineComponent } from "vue";
import {
  mdiGitlab,
  mdiGithub,
  mdiSourceBranch,
  mdiLaunch,
  mdiInformation,
} from "@mdi/js";
export default defineComponent({
  methods: { translate },
  props: ["project"],
  data() {
    return {
      mdiGitlab,
      mdiGithub,
      mdiSourceBranch,
      mdiLaunch,
      mdiInformation,
    };
  },
  computed: {
    infoUrl() {
      return `/project/${this.project.id}`;
    },
    icon() {
      if (this.project.source.includes("github")) return mdiGithub;
      if (this.project.source.includes("gitlab")) return mdiGitlab;
      return mdiSourceBranch;
    },
  },
});
</script>
