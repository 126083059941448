<template>
  <v-chip-group column>
    <v-chip v-if="includeAll">All</v-chip>
    <v-chip v-for="tag in tags" :key="tag.id" :href="`/tag/${tag.id}`">
      <v-icon v-if="tag.icon" :icon="tag.icon" />
      <Translation :item="tag.name" />
    </v-chip>
  </v-chip-group>
</template>
<script setup lang="ts">
</script>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Tag } from "@/types";
export default defineComponent({
  props: {
    tags: {
      type: Array as PropType<Tag[]>,
      required: true,
    },
    includeAll: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
